    @import "styles/variables";
    @import "styles/mixins";

$story-container-max-width: 926px;

.story {
    &.withSidebar {
        display: grid;

        // grid-template-rows: 75px auto;
        grid-template-columns: auto 360px;
        grid-gap: 40px;
        grid-template-areas: "content sidebar";

        @include mobile-only {
            display: block;
        }
    }
}

.sidebar {
    grid-area: sidebar;
    padding: $spacing-6 0;
}

.image {
    margin: $spacing-4 auto;

    @include tablet-up {
        margin: $spacing-6 auto;
    }

    @include desktop-up {
        margin: $spacing-8 auto;
        max-width: $story-container-max-width;
    }
}

.middle {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.imageWrapper {
    position: relative;
}

.imageWrapper:hover .image {
    opacity: 0.3;
}

.imageWrapper:hover .middle {
    opacity: 1;
}

.download {
    color: #fff;
    font-size: 16px;
}

.fullWidthImage {
    @include full-width;

    max-height: 460px;
    z-index: -1;
}

.container {
    margin: 0 auto;
    padding: $spacing-6 0;
    background: $color-base-white;

    @include desktop-up {
        max-width: $story-container-max-width;
        padding: $spacing-9;
    }

    &.withImage {
        padding-top: $spacing-5;

        @include desktop-up {
            padding-top: 0;
        }
    }

    &.withSidebar {
        grid-area: content;
        padding: $spacing-6 0;
    }
}

.meta {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    white-space: pre;
    margin-bottom: $spacing-4;
}

.title {
    @include heading-1-xxl;

    margin: 0 0 $spacing-4;
    color: $color-base-700;
}

.subtitle {
    @include heading-2;

    margin: 0 0 $spacing-4;
    color: $color-text;
    font-weight: $font-weight-regular;
}

.separator {
    width: 100%;
    height: 1px;
    margin: $spacing-7 auto;
    background: $color-base-200;
}

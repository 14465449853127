    @import "styles/variables";
    @import "styles/mixins";

.container {
    padding: $spacing-9 0;
    color: $color-base-white;
    background: $color-brand-boilerplate;
}

.columns {
    display: block;

    @include desktop-up {
        display: flex;
    }
}

.heading {
    @include heading-3;

    margin: $spacing-3 0;
    color: $color-base-white;
}

.aboutUs {
    flex-grow: 2;
    margin-bottom: $spacing-6;

    @include desktop-up {
        margin-bottom: 0;

        + .contacts {
            margin-left: $spacing-6;
        }
    }
}

.contacts {
    @include tablet-up {
        min-width: 350px;
    }
}

.about {
    margin-bottom: $spacing-5;

    p {
        @include text-label;
    }

    * {
        color: inherit;
    }
}

.contact {
    @include text-label;

    display: flex;
    align-items: flex-start;

    .icon {
        margin-right: $spacing-1;

        // arbitrary value to align icon to text
        margin-top: 0.1875rem;
        flex-shrink: 0;
    }
}

.socialMedia {
    margin-bottom: $spacing-3;
}

.link,
.about a {
    @include link-white;
}

.separator {
    width: 1.5rem;
    height: 0.125rem;
    background: $color-base-white;
}

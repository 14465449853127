    @import "styles/variables";
    @import "styles/mixins";

.container {
    color: $color-base-700;
    text-decoration: none;

    &:hover,
    &:focus {
        color: $color-base-500;
    }
}

.thumbnail {
    @include border-radius-s;

    object-position: top;
    height: 480px !important;
    margin: 0 auto $spacing-4;
    border: 1px solid $color-borders;
}

.title {
    @include heading-2;

    color: $color-headings;
    text-decoration: underline;
}

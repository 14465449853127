    @import "styles/variables";
    @import "styles/mixins";

.background {
    @include full-width;
}

.container {
    @include container;

    padding: $spacing-8;
    text-align: center;

    @include mobile-only {
        padding: $spacing-5;
    }
}

.title {
    @include heading-1;

    margin-top: 0;
    color: $color-text;

    &:only-child {
        margin-bottom: 0;
    }
}

.subtitle {
    @include paragraph;

    max-width: 735px;
    margin: $spacing-3 0;
}

    @import "styles/variables";
    @import "styles/mixins";

.contacts {
    padding: $spacing-6 0;
    border-top: 1px solid $color-borders;
}

.container {
    @include container;
}

.title {
    @include heading-2;

    margin: 0 0 $spacing-4;
    color: $color-base-700;
}

.grid {
    display: grid;
    gap: $spacing-6;
    grid-template-columns: 1fr;

    @include not-desktop {
        grid-template-columns: 1fr !important;
    }

    &.twoColumns {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    &.threeColumns {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

    @import "styles/variables";
    @import "styles/mixins";

$small-card-image-size: 230px;

.container {
    text-decoration: none;
    position: relative;
}

.imageWrapper {
    @include border-radius-s;

    display: block;
    overflow: hidden;
    text-decoration: none;
    border: 1px solid $color-borders;

    @include desktop-up {
        .image {
            transform: scale(1);
            transition: transform 0.5s ease-in-out;
        }

        &:hover .image {
            transform: scale(1.05);
        }
    }
}

.image,
.placeholder {
    @include border-radius-s;
}

.placeholder > .image {
    border-radius: 0;
}

.content {
    padding: $spacing-3 0;
}

.categories {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $spacing-1;
}

.title {
    @include heading-4;

    text-decoration: underline;
    margin: 0;
}

.titleSmaller {
    @include ensure-max-text-height(3, $line-height-m);
    @include heading-4;
}

.titleLarger {
    @include heading-3;
    @include ensure-max-text-height(3, $line-height-m);
}

.titleLink {
    color: inherit;

    &:hover,
    &:focus {
        color: $color-brand-accent-hover;
    }
}

.subtitle {
    @include heading-3;
    @include ensure-max-text-height(2, $line-height-m);

    margin: 0 auto $spacing-2;
    font-weight: $font-weight-regular;
}

.date {
    @include text-label;

    margin: 0;
    margin-bottom: $spacing-1;
    color: $color-text;
}

.small {
    @include tablet-up {
        display: grid;
        grid-template-columns: $small-card-image-size auto;
        gap: 0 $spacing-4;
        align-items: center;
    }

    .imageWrapper {
        @include small-card-aspect-ratio;
    }

    .content {
        @include tablet-up {
            padding: 0;
        }
    }

    .title {
        @include tablet-up {
            @include heading-3;
            @include ensure-max-text-height(3, $line-height-m);

            margin-bottom: $spacing-1;
        }

        @include desktop-up {
            @include paragraph;
            @include ensure-max-text-height(3, $line-height-s);
        }
    }

    .subtitle {
        @include tablet-up {
            @include paragraph;
            @include ensure-max-text-height(2, $line-height-s);
        }
    }
}

.medium,
.big {
    @include tablet-only {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        gap: 0 $spacing-4;

        .content {
            padding: 0;
        }
    }
}

.medium {
    .imageWrapper {
        @include medium-card-aspect-ratio;
    }
}

.big {
    .imageWrapper {
        @include big-card-aspect-ratio;
    }
}

@include tablet-only {
    .medium + .small {
        margin-top: $spacing-9;
    }
}

@include desktop-up {
    .medium {
        grid-column: span 2;
    }

    .big,
    .small {
        grid-column: span 3;
    }
}

    @import "styles/variables";
    @import "styles/mixins";

.layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.content {
    @include container;

    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: $spacing-8;
    padding-bottom: $spacing-9;

    @include mobile-only {
        padding-top: $spacing-5;
    }
}

@media screen and (width <= 1350px) {
    .line {
        background-image: url("/images/banner-1320x12.svg");
        min-height: 10px;
    }
}

@media screen and (width >= 1350px) {
    .line {
        background-image: url("/images/banner-16x1200.svg");
        height: 100%;
        left: 0;
        min-height: fit-content;
        position: fixed;
        width: 10px;
        z-index: 10;
    }
}

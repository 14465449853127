    @import "styles/variables";
    @import "styles/mixins";

.container {
    width: 100%;
    margin: $spacing-9 auto;

    @include mobile-only {
        margin: $spacing-4 auto;
    }
}

.title {
    @include heading-1;
}

.description {
    font-size: $font-size-l;
    line-height: $line-height-l;
    font-weight: $font-weight-regular;
    color: $color-base-500;
}

.links {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.shareLinks {
    @include mobile-only {
        margin: $spacing-2 0 0;
    }
}

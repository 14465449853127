    @import "styles/variables";
    @import "styles/mixins";

.container {
    position: relative;
    display: block;
    text-decoration: none;

    @include desktop-up {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        gap: 0 $spacing-6;
    }

    &:not(:last-of-type) {
        margin-bottom: $spacing-7;

        @include desktop-up {
            margin-bottom: $spacing-9;
        }
    }
}

.imageWrapper {
    @include highlighted-card-aspect-ratio;

    display: block;
    text-decoration: none;
    overflow: hidden;
    border: 1px solid $color-borders;

    @include desktop-up {
        .image {
            transform: scale(1);
            transition: transform 0.5s ease-in-out;
        }

        &:hover .image {
            transform: scale(1.05);
        }
    }

    .placeholder > .image {
        border-radius: 0;
    }
}

.content {
    padding: $spacing-4;

    @include desktop-up {
        padding: $spacing-9 $spacing-6;
        padding-left: 0;
    }
}

.dateAndCategory {
    @include text-label;

    display: flex;
    flex-wrap: wrap;
}

.separator {
    margin: 0 $spacing-half;
    font-weight: $font-weight-bold;
    font-size: $font-size-m;
}

.title {
    @include heading-1;

    margin-top: $spacing-1;
    margin-bottom: 0;
    text-decoration: underline;

    &.huge {
        @include heading-2;
    }

    &:hover,
    &:focus {
        color: $color-brand-accent-hover;
    }
}

.subtitle {
    @include heading-3;

    margin-bottom: 0;
    margin-top: $spacing-3;
    color: $color-base-600;
    font-weight: $font-weight-regular;

    &.limited {
        @include ensure-max-text-height(3, $line-height-s);
    }

    &.hidden {
        @include desktop-up {
            display: none;
        }
    }
}

.date {
    color: $color-brand-boilerplate;
}

    @import "styles/variables";
    @import "styles/mixins";

.container {
    display: flex;
    position: relative;
}

.paste {
    border: 0 !important;
    padding: 0;
    height: $spacing-6;
    width: $spacing-6;
    font-size: $font-size-m;
}

.messageIcon {
    width: $spacing-4;
    height: $spacing-4;
    margin-right: $spacing-1;
}

.message {
    color: $color-text;
    position: absolute;
    transition: opacity 0.4s ease;
    will-change: opacity;
    inset: 0 0 0 -9rem;
    display: flex;
    align-items: center;
    background-color: $color-base-white;
}

.transitionStart {
    opacity: 0;
}

.transitionFinish {
    opacity: 1;
}

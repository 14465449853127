    @import "styles/variables";
    @import "styles/mixins";

.container {
    @include container;

    padding-top: $spacing-8;
    padding-bottom: $spacing-8;
    background-color: $color-brand-purple;
    border-top: 1px solid $color-borders;
    text-align: center;
    color: #FFF;

    @include desktop-up {
        max-width: none;
    }
}

.link {
    color: #fff;
}

.title {
    @include heading-1;

    color: #FFF;
    margin-top: 0;
    margin-bottom: $spacing-5;
}

.inlineForm {
    text-align: left;

    @include tablet-up {
        display: grid;
        grid-template-columns: 1fr auto;
        gap: $spacing-2;
    }

    @include desktop-up {
        margin-left: auto;
        margin-right: auto;
        max-width: 520px;
    }
}

.button {
    width: 100%;
    align-self: start;
}

.input {
    @include tablet-up {
        margin-bottom: 0 !important;
    }
}

.disclaimer {
    @include text-small;

    margin-top: $spacing-3 + $spacing-half;
    margin-bottom: 0;
}

.disclaimerLink {
    font-weight: $font-weight-medium;
    color: $color-base-white;

    &:hover,
    &:focus {
        color: $color-brand-accent;
    }
}

    @import "styles/variables";
    @import "styles/mixins";

.container {
    @include mobile-only {
        background: $color-base-50;
    }
}

.menu {
    @include tablet-up {
        max-height: 50vh;
        overflow: auto;
    }
}

.disabled {
    pointer-events: none;
    opacity: 0.5;
}

    @import "styles/variables";
    @import "styles/mixins";

.button {
    background-color: transparent;
    border: 0;
    padding: 0;
    height: $spacing-6;
    width: $spacing-6;
    font-size: $font-size-m;
}

    @import "styles/variables";
    @import "styles/mixins";

.wrapper {
    @include full-width;

    background-color: $color-base-50;
    padding: $spacing-4 0;
    margin-bottom: $spacing-6;
    position: relative;
}

.container {
    @include container;

    display: flex;
    width: 100%;
    justify-content: space-between;

    @include not-desktop {
        flex-direction: column;
    }
}

.facets {
    @include border-radius-m;

    padding: 0;
    display: flex;
    align-items: center;

    @include not-desktop {
        margin-top: $spacing-3;
        width: 100%;
    }

    @include mobile-only {
        flex-direction: column;
        display: none;

        &.facetsOpen {
            display: flex;
        }
    }

    &:empty {
        display: none;
    }
}

.header {
    display: flex;
    align-items: flex-start;

    @include tablet-up {
        min-width: 300px;
    }

    @include desktop-large-up {
        min-width: 450px;
    }
}

.button {
    margin-left: $spacing-3;
    padding: calc(#{$spacing-3} - 1px) $spacing-3;

    @include desktop-up {
        display: none;
    }
}

.filters {
    @include text-label;

    margin-right: $spacing-3;
    font-weight: $font-weight-bold;
    color: $color-base-500;

    @include not-desktop {
        display: none;
    }
}

.toggleFacets {
    justify-content: flex-start;
    padding-left: 0;
    margin-top: $spacing-3;
}

    @import "styles/variables";
    @import "styles/mixins";

.container {
    @include text-small;

    padding: $spacing-5 0;
    color: $color-base-white;
    background: $color-brand-footer;
}

.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include mobile-only {
        display: block;
    }
}

.links {
    display: flex;
    align-items: center;

    @include mobile-only {
        flex-direction: column;
        justify-content: center;

        &:not(:last-child) {
            margin-bottom: $spacing-4;
        }
    }
}

.link {
    @include link-white;

    display: block;
    padding: 0;
    appearance: none;
    background: none;
    border: 0;
    cursor: pointer;

    & + & {
        margin-left: $spacing-3;

        @include mobile-only {
            margin: $spacing-2 0 0;
        }
    }
}

.poweredBy {
    display: flex;
    align-items: center;

    @include mobile-only {
        justify-content: center;
    }
}

.prezly {
    margin-left: $spacing-1;
    line-height: 0;
    color: $color-base-white;
}

.prezlyLogo {
    height: 1.25rem;
}

    @import "styles/variables";
    @import "styles/mixins";

.container {
    display: flex;
    flex-direction: row;
    margin: $spacing-6 0;
}

.button {
    &:not(:last-child) {
        margin-right: $spacing-1;
    }
}
